<template>
  <div v-frag>
    <v-container fluid>
      <v-alert
        v-model='alertOptions.show'
        :color='alertOptions.color'
        class='rounded-xl'
        dark
        dense
        dismissible
        icon='mdi-check-circle-outline'
        prominent
        transition='scale-transition'
        width='100%'
      >
        <h3 class='mt-2'>{{ alertOptions.title }}</h3>
        <p>{{ alertOptions.body }}</p>
      </v-alert>
      <v-row>
        <v-col class='pb-0' lg='5' md='5' sm='12'>
          <v-row>
            <v-col>
              <v-card elevation='3'>
                <v-card-title class='mb-2'>
                  <v-row>
                    <v-col class='charity-name-section'>
                      <v-avatar color='grey' size='110'>
                        <v-img :src='charity.logo'></v-img>
                      </v-avatar>
                      <p class='charity-name grey--text'>{{ charity.name }}</p>
                    </v-col>
                    <v-col class='contactBlock' lg='12' md='12' sm='12'>
                      <v-card-text class='font-weight-medium' size='4rem'
                      >Address
                      </v-card-text
                      >
                      <v-card-text class='font-weight-light'>{{
                          charity.address
                                                             }}
                      </v-card-text>
                    </v-col>
                    <v-col class='contactBlock' lg='12' md='12' sm='12'>
                      <v-card-text class='font-weight-medium'
                      >Phone
                      </v-card-text
                      >
                      <v-card-text class='font-weight-light'>{{
                          charity.general_phone
                                                             }}
                      </v-card-text>
                    </v-col>
                  </v-row>
                </v-card-title>
              </v-card>
            </v-col>
          </v-row>
          <v-row class='my-0'>
            <v-col>
              <v-card class='pa-6 charityCard' elevation='3'>
                <v-row>
                  <v-col>
                    <v-card-title class='d-flex justify-center'
                    >Donate to this charity
                    </v-card-title
                    >
                  </v-col>
                </v-row>
                <v-row>
                  <v-col class='py-0'>
                    <v-btn
                      class='px-14 py-0 font-weight-bold white--text'
                      color='primary'
                      large
                      width='100%'
                      @click='openModalDonate(charity)'
                    >
                      Donate
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
        <v-col class='description-block' lg='7' md='7' sm='12'>
          <v-card
            class='d-flex flex-column align-stretch'
            elevation='3'
            height='100%'
          >
            <div>
              <v-card-title>Mission</v-card-title>
              <v-card-text class='px-8 d-flex justify-left font-weight-light'>{{
                  charity.mission
                                                                              }}
              </v-card-text>
            </div>
            <div>
              <v-card-title>Description</v-card-title>
              <v-card-text class='px-8 d-flex justify-left font-weight-light'>{{
                  charity.description
                                                                              }}
              </v-card-text>
            </div>
          </v-card>
        </v-col>
      </v-row>
    </v-container>

    <v-container fluid>
      <v-card elevation='3'>
        <TableVolunteeringOpportunities v-on:requested='openRequestAlert'/>
      </v-card>
    </v-container>

    <v-container fluid>
      <v-card class='text-center rounded-lg' elevation='3'>
        <v-card-title class='cardTitle'>
          Open Fundraising Opportunities
        </v-card-title>
        <v-card-subtitle>
          "Those who are happiest are those who do the most for others"
        </v-card-subtitle>
        <v-card-text> Booker T. Washington</v-card-text>
      </v-card>
    </v-container>

    <v-container v-for='donation of donations' v-bind:key='donation.id' fluid>
      <v-card>
        <v-row align='center' align-content='center' justify='center'>
          <v-col cols='12' lg='12' md='12' sm='12' xl='12' xs='12'>
            <v-row>
              <v-col
                class='d-flex flex-column avatarSection'
                lg='3'
                md='3'
                sm='12'
              >
                <v-row>
                  <v-col>
                    <div class='avatarCharity'>
                      <v-avatar color='grey' size='180'>
                        <v-img :src='donation[0].logo'></v-img>
                      </v-avatar>
                      <div class='d-flex flex-column mb-6 progressBar'>
                        <v-row>
                          <span class='percentage'
                          >{{ donation[0].percentage }}%</span
                          >
                        </v-row>
                        <v-row class='progressLinaer'>
                          <v-progress-linear
                            :value='donation[0].percentage'
                            height='15'
                            rounded
                          ></v-progress-linear>
                        </v-row>
                        <v-row class='currencies'>
                          <span>$0,00</span>
                          <span>{{
                              formatMoney(donation[0].mount_required)
                                }}</span>
                        </v-row>
                      </div>
                    </div>
                  </v-col>
                </v-row>
              </v-col>
              <v-col class='tablesSection' lg='9' md='9' sm='12'>
                <v-data-table
                  :headers='dialogHeaders'
                  :items='donation'
                  :items-per-page='5'
                  class='elevation-1 topDataTable'
                  hide-default-footer
                >
                  <template v-slot:[`item.mount_required`]='{ item }'>
                    <span> {{ formatMoney(item.mount_required) }} </span>
                  </template>
                  <template v-slot:[`item.total_donations`]='{ item }'>
                    <span> {{ formatMoney(item.total_donations) }} </span>
                  </template>

                  <template v-slot:[`item.end`]='{ item }'>
                    <span> {{ item.end }} </span>
                  </template>
                </v-data-table>
                <v-simple-table class='elevation-1 descriptionText' dense>
                  <template v-slot:default>
                    <thead>
                    <tr>
                      <th class='text-left'>Description</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                      <td>
                        {{ donation[0].description }}
                      </td>
                    </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row class='donationSection'>
          <v-spacer></v-spacer>
          <v-col lg='3' md='3' sm='12'>
            <v-btn
              class='px-14 py-4 font-weight-bold white--text'
              color='primary'
              large
              @click='openModalDonate(donation[0])'
            >
              Donate
            </v-btn>
          </v-col>
        </v-row>
      </v-card>
    </v-container>
    <ModalDonation
      :item='itemToDonate'
      :open='dialogDonation'
      v-on:close='closeModalDonate'
      v-on:donated='openAlert(true)'
      v-on:error='openAlert(false)'
    />
  </div>
</template>

<script>
import TableVolunteeringOpportunities from './components/TableVolunteeringOpportunities'
import ModalDonation from './components/ModalDonate.vue'
import charityService from '../../services/charity/index'
import donationService from '../../services/donations/index'

export default {
  components: {
    TableVolunteeringOpportunities,
    ModalDonation
  },
  data() {
    return {
      charity: {},
      monthly: 0,
      oneTime: 0,
      dialogDonation: false,
      itemToDonate: {},
      alertOptions: {
        show: false
      },
      donations: [],
      fundsForCharity: {},
      donation: [],
      dialogHeaders: [
        {text: 'Need', value: 'need', aling: 'center', divider: true},
        {
          text: 'Funds needed',
          value: 'mount_required',
          aling: 'center',
          divider: true
        },
        {
          text: 'Amount Raised',
          value: 'total_donations',
          aling: 'center',
          divider: true
        },
        {text: 'Deadline', value: 'end', aling: 'center', divider: true}
      ]
    }
  },
  methods: {
    async initialize() {
      const charityId = this.$store.getters.charity
      this.charity = await charityService.showCharity(charityId)
      donationService.showDonations(charityId)
        .then((res) => {
          const formatDonations = []
          res.forEach((eventDonation) => {
            if (eventDonation.event_type_id == 2) {
              const percentage = Math.round(
                (eventDonation.total_donations * 100) /
                eventDonation.mount_required
              )
              eventDonation.percentage = percentage > 100 ? 100 : percentage
              formatDonations.push([eventDonation])
            } else {
              this.fundsForCharity = eventDonation
            }
          })
          this.donations = formatDonations
        })
    },
    openModalDonate(item = {}) {
      this.dialogDonation = true
      if (item.event_type_id) {
        this.itemToDonate = {
          eventId: item.id,
          charityId: item.charity_id,
          charityName: item.charity_name
        }
      } else {
        this.itemToDonate = {
          eventId: this.fundsForCharity.id,
          charityId: item.id,
          charityName: this.charity.name
        }
      }
    },
    closeModalDonate() {
      this.itemToDonate = {}
      this.dialogDonation = false
    },
    /* openAlert() {
      this.alertOptions = {
        show: true,
        color: "success",
        title: "Funds Donated",
        body: "The donation was made successfully",
      };
    }, */
    openAlert(status) {
      window.scrollTo(0, 0)
      this.alertOptions = {
        show: true,
        color: 'success',
        title: 'Funds Donated',
        body: 'The donation was made successfully'
      }
      if (status == false) {
        this.alertOptions.color = 'error'
        this.alertOptions.title = 'Something went wrong!'
        this.alertOptions.body = 'Something has gone wrong, please try again.'
      }
    },
    openRequestAlert() {
      this.alertOptions = {
        show: true,
        color: 'success',
        title: 'Request Submitted',
        body: 'The volunteering request was made successfully'
      }
    },
    formatMoney: function(value) {
      if (typeof value == 'number') {
        return value.toLocaleString('en-US', {
          style: 'currency',
          currency: 'USD'
        })
      }
    }
  },
  created() {
    this.initialize()
  },
  mounted() {
    window.scrollTo(0, 0)
  }
}
</script>

<style>
.charity-name-section {
  display: flex;
  align-items: center;
}
.charity-name-section p {
  margin: 1rem;
}
.description-block {
  padding-left: 0;
}
.charity-name {
  word-wrap: break-word;
}
.contactBlock {
  display: flex;
  border-top: 1px solid #eee;
}
</style>
