<template>
  <div v-frag>
    <v-container fluid>
      <v-card-title>
        <v-row align='center' align-content='center' justify='center'>
          <v-col cols='12' lg='12' md='12' sm='12' xl='12' xs='12'>
            <div class='text-h5 font-weight-black grey--text text--darken-1'>
              Open Volunteer Opportunities
            </div>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text>
        <v-card
          class='rounded-lg'
          elevation='0'
          style='border: 2px solid #ecf1f9'
        >
          <v-row>
            <v-col cols='12' lg='12' md='12' sm='12' xl='12' xs='12'>
              <v-data-table
                :headers='headers'
                :items='desserts'
                :items-per-page='10'
                :loading='loading'
                :options.sync='options'
                :search='search'
                :server-items-length='totalDesserts'
                class='elevation-0 data-table-border-radius'
                hide-default-footer
              >
                <template v-slot:[`item.spots`]='{ item }'>
                  <div :class="item.colormember + '--text'">
                    {{ item.membersCount }} / {{ item.volunteersRequired }}
                  </div>
                </template>
                <template v-slot:[`item.actions`]='{ item }'>
                  <v-btn
                    :disabled='item.membersCount < 1'
                    color='primary'
                    depressed
                    small
                    @click='openRequestModal(item)'
                  >
                    Available Dates
                  </v-btn>
                </template>
              </v-data-table>

              <ModalRequest
                v-if='showDialog'
                :item='item'
                :open='showDialog'
                v-on:apply='submitRequest'
                v-on:close='closeRequestModal'
              ></ModalRequest>
            </v-col>
          </v-row>
        </v-card>
      </v-card-text>
    </v-container>
  </div>
</template>

<script>
import opportunitiesService from '../../../services/opportunities/index'
import employee from '../../../services/employee'
import ModalRequest from './ModalRequest.vue'

export default {
  components: {ModalRequest},
  mounted() {
    const charity = this.$store.getters.user.profile_id.charity_id
    if (charity) {
      this.charityLogged = true
    }
    this.loadInitial()
  },
  data() {
    return {
      item: [],
      showDialog: false,
      headers: [
        {
          text: 'Need',
          align: 'start',
          sortable: true,
          value: 'need',
          divider: true
        },
        {
          text: 'Open Dates From',
          align: 'start',
          sortable: true,
          value: 'start',
          divider: true
        },
        // {
        //   text: "End Date",
        //   align: "start",
        //   sortable: true,
        //   value: "end",
        //   divider: true,
        // },
        {
          text: 'Hs.',
          align: 'start',
          sortable: true,
          value: 'hoursRequired',
          divider: true
        },
        {
          text: 'Time',
          align: 'start',
          sortable: true,
          value: 'time',
          divider: true
        },
        {
          text: 'Address',
          align: 'start',
          sortable: true,
          value: 'address',
          divider: true
        },
        {
          text: 'Spots',
          align: false,
          sortable: false,
          value: 'spots',
          divider: true
        },
        {
          text: 'Actions',
          align: false,
          sortable: false,
          value: 'actions',
          divider: true
        }
      ],
      desserts: [],
      charityLogged: false,
      totalDesserts: 0,
      search: '',
      loading: false,
      options: {}
    }
  },
  methods: {
    async loadInitial() {
      const res = await opportunitiesService.getCharityOpportunities(
        this.$store.getters.charity
      )
      this.totalDesserts = res.length
      this.desserts = res
    },
    async submitVolunteeringRequest(employeeId, eventId) {
      try {
        await employee.submitVolunteerRequest({
          employeeId: employeeId,
          eventDayId: eventId
        })
      } catch (err) {
        console.log(err)
      }
    },
    openRequestModal(item) {
      this.item.push(item)
      this.showDialog = true
    },
    closeRequestModal() {
      this.item = []
      this.showDialog = false
      window.scrollTo(0, 0)
    },
    async submitRequest(request) {
      const employeeId = request.employeeId
      const eventId = request.eventId
      await this.submitVolunteeringRequest(employeeId, eventId)
      await this.loadInitial()
      this.$emit('requested')
    }
  }
}
</script>
