import {HTTP} from '../../../http-common'
import helper from '../index'

export default {
  getActivesCharities: async() => {
    const response = await HTTP.get('charities', {
      headers: helper.setHeaders()
    })
    return response.data.data
  },
  showCharity: async(id) => {
    try {
      const response = await HTTP.get('charity/' + id, {
        headers: helper.setHeaders()
      })
      return response.data.data
    } catch (e) {
      helper.errorHandler(e.response)
      return e.response
    }
  },
  getProfile: async() => {
    try {
      const response = await HTTP.get('/charity/profile/get', {
        headers: helper.setHeaders()
      })
      return response.data.data
    } catch (e) {
      helper.errorHandler(e.response)
      return e.response
    }
  },
  updatedProfile: async({
    address,
    city,
    state,
    zipCode,
    phone,
    email,
    mision,
    description,
    image,
    fileType
  }) => {
    try {
      let formData = new FormData()
      let headers = helper.setHeaders()
      headers['Content-Type'] = 'multipart/form-data'
      formData.append('address', address)
      formData.append('city', city)
      formData.append('state', state)
      formData.append('zip_code', zipCode)
      formData.append('contact_phone', phone)
      formData.append('email', email)
      formData.append('mission', mision)
      formData.append('description', description)
      if (fileType) {
        formData.append('logo', image, {type: fileType})
      } else formData.append('logo', '')
      const response = await HTTP.post('charity/profile/update', formData, {
        headers: headers
      })
      return response.data
    } catch (e) {
      helper.errorHandler(e.response)
      return e.response
    }
  },
  getVolunteering: async() => {
    try {
      const response = await HTTP.get('opportunities/profile', {
        headers: helper.setHeaders()
      })
      return response.data.data
    } catch (e) {
      helper.errorHandler(e.response)
      return e.response
    }
  },
  getFundraising: async() => {
    try {
      const response = await HTTP.get('/charity/profile/getFundraising', {
        headers: helper.setHeaders()
      })
      return response.data.data
    } catch (e) {
      helper.errorHandler(e.response)
      return e.response
    }
  },
  updatedProfileBankAccount: async({name, account, routing_number}) => {
    try {
      const response = await HTTP.post(
        '/bank-account/store/account',
        {
          account_holder_name: name,
          account_number: account,
          routing_number
        },
        {
          headers: helper.setHeaders()
        }
      )
      return response.data
    } catch (e) {
      helper.errorHandler(e.response)
      return e.response
    }
  },
  verifyBankAccount: async(data) => {
    try {
      const response = await HTTP.post('/bank-account-verify', data, {
        headers: helper.setHeaders()
      })
      return response.data
    } catch (e) {
      return e.response
    }
  },
  getProfileBankAccount: async() => {
    try {
      const response = await HTTP.get('/bank-account/get/account', {
        headers: helper.setHeaders()
      })
      if (!response.data.data) {
        return {status_account: 0}
      }
      return response.data.data
    } catch (e) {
      helper.errorHandler(e.response)
      return e.response
    }
  },

  getTableFundraisingNeeds: async(data) => {
    try {
      const response = await HTTP.get(
        'charity/profile/getTableFundraisingNeeds',
        {
          params: data,
          headers: helper.setHeaders()
        }
      )
      return response.data.data
    } catch (e) {
      helper.errorHandler(e.response)
      return e.response
    }
  },

  getTableDonationOverview: async(data) => {
    try {
      const response = await HTTP.get('charity/overview/donation', {
        params: data,
        headers: helper.setHeaders()
      })
      return response.data.data
    } catch (e) {
      helper.errorHandler(e.response)
      return e.response
    }
  },

  getTableVolunteerOverview: async(data) => {
    try {
      const response = await HTTP.get('charity/overview/volunteer', {
        params: data,
        headers: helper.setHeaders()
      })
      return response.data.data
    } catch (e) {
      helper.errorHandler(e.response)
      return e.response
    }
  },

  detailCompany: async(id) => {
    try {
      const response = await HTTP.get(
        'opportunities/profile/detailCompany/' + id,
        {
          headers: helper.setHeaders()
        }
      )
      return response.data.data
    } catch (e) {
      helper.errorHandler(e.response)
      return e.response
    }
  }
}
